.App {
    text-align: center;
    background-color: #071524;
}

.Section {
    padding-top: 30px;
    /* padding-bottom: 20px; */

    /* border-top: 1px solid #5A738D; */
    /* border-bottom: 0.1px solid #5A738D; */
}

.Section:nth-child(odd) {
    background-color: #071524;
}

.Section:nth-child(even) {
    background-color: #051322;
}

@keyframes sectionInAnimation {
    from {
        transform: translate3d(0, 10vh, 0);
        opacity: 0;
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes sectionOutAnimation {
    from {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }

    to {
        transform: translate3d(0, 10vh, 0);
        opacity: 0;
    }
}

@keyframes FlyInLeft {
    from {
        transform: translate3d(-20vh, 0, 0);
        opacity: 0;
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.flyInLeft {
    animation-name: FlyInLeft;
    animation-timing-function: cubic-bezier(0, .81, 0, 1);
    animation-fill-mode: forwards;
}

@keyframes FlyInRight {
    from {
        transform: translate3d(20vh, 0, 0);
        opacity: 0;
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.flyInRight {
    animation-name: FlyInRight;
    animation-timing-function: cubic-bezier(0, .81, 0, 1);
    animation-fill-mode: forwards;
}

@keyframes FlyOutLeft {
    from {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }

    to {
        transform: translate3d(-20vh, 0, 0);
        opacity: 0;
    }
}

.flyOutLeft {
    animation-name: FlyOutLeft;
    animation-timing-function: cubic-bezier(0, .81, 0, 1);
    animation-fill-mode: forwards;
}

@keyframes FlyOutRight {
    from {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }

    to {
        transform: translate3d(20vh, 0, 0);
        opacity: 0;
    }
}

.flyOutRight {
    animation-name: FlyOutRight;
    animation-timing-function: cubic-bezier(0, .81, 0, 1);
    animation-fill-mode: forwards;
}

.fadeInUp {
    animation-name: sectionInAnimation;
    animation-duration: 1s;
}

.fadeOutDown {
    animation-name: sectionOutAnimation;
    animation-duration: 1s;
}

@keyframes FadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    animation-name: FadeIn;
    animation-duration: 1.0s;
    animation-fill-mode: forwards;
    animation-delay: 1.0s;
}

@keyframes FadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.fadeOut {
    animation-name: FadeOut;
    animation-duration: 1.0s;
    animation-fill-mode: forwards;
    animation-delay: 1.0s;
}

@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 100;
    src: url(https://fonts.gstatic.com/s/jost/v14/92zPtBhPNqw79Ij1E865zBUv7myjJTVBNIg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Grifter';
    font-style: normal;
    font-weight: 100;
    src: url('/public/fonts/grifterbold.otf') format('opentype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}